import React from "react";
import { Route, Redirect, NavLink, Switch, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { removeHttpPrefix } from "@shared/lib/utils";

import NavItem from "@ats/src/components/shared/NavItem";
import CloningJobModal from "@ats/src/components/modals/CloningJobModal";
import ArchiveJobModal from "@ats/src/components/modals/ArchiveJobModal";
import HideJobModal from "@ats/src/components/modals/HideJobModal";
import SubscriptionRequiredModal from "@ats/src/components/modals/SubscriptionRequiredModal";
import SubscriptionRequiredModalNew from "@ats/src/components/modals/SubscriptionRequiredModalNew";
import ShareJobModal from "@ats/src/components/modals/ShareJobModal";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import Button from "@ats/src/components/shared/Button";
import Icon from "@ats/src/components/shared/Icon";
import JobSetupDetails from "@ats/src/views/jobApplications/jobSetup/JobSetupDetails";
import JobSetupDescription from "@ats/src/views/jobApplications/jobSetup/JobSetupDescription";
import JobSetupApplicationForm from "@ats/src/views/jobApplications/jobSetup/JobSetupApplicationForm";
import JobSetupDocument from "@ats/src/views/jobApplications/jobSetup/JobSetupDocument";
import JobSetupTeam from "@ats/src/views/jobApplications/jobSetup/JobSetupTeam";
import JobSetupHiringStages from "@ats/src/views/jobApplications/jobSetup/JobSetupHiringStages";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";

import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { getTimestamp } from "@shared/queryHooks/useOrganization";
import {
  useUpdateJob,
  usePublishJob,
  useCloneJob,
  useForceWebflowSyncForJob,
} from "@shared/queryHooks/useJob";
import { useCreateSubscription } from "@shared/queryHooks/useBilling";
import JobSetupAutomations from "./JobSetupAutomations";

import { useFeatureFlipper, Features } from "@ats/src/components/shared/FeatureFlipper";

function JobSetupContainer(props) {
  const { match, job, location } = props;
  const { currentUser, currentOrganizationUser, currentOrganization } = useCurrentSession();

  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();
  const [isDirty, setIsDirty] = React.useState(false);

  const planBasedBillingEnabled = useFeatureFlipper()({ feature: Features.PLAN_BASED_BILLING });

  const { mutate: updateJob, isLoading: isLoadingUpdateJob } = useUpdateJob();
  const { mutate: publishJob, isLoading: isLoadingPublishJob } = usePublishJob();
  const {
    mutate: forceWebflowSyncForJob,
    isLoading: isLoadingForceWebflowSyncForJob,
  } = useForceWebflowSyncForJob();
  const { mutate: cloneJob, isLoading: isLoadingCloneJob } = useCloneJob();
  const {
    mutate: createSubscription,
    isLoading: isLoadingCreateSubscription,
  } = useCreateSubscription();

  window.logger("%c[JobSetupContainer] ", "background: #EFDDEF; color: #1976D2", {
    currentUser,
    currentOrganization,
    props,
  });

  const jobUrl = `${currentOrganization?.careersPageUrl}/${job.id}`;

  const handleClickOnViewPreview = () => {
    getTimestamp().then((data) => {
      window.logger(
        "%c[JobSetupContainer] handleClickOnViewPreview getTimestamp",
        "color: #1976D2",
        {
          data,
        },
      );
      window.open(`${jobUrl}/preview/${data.timestamp}`, "_blank");
    });
  };

  const handleToggleJobVisibility = (event) => {
    event.preventDefault();
    if (job.visible === true) {
      const modal = (
        <HideJobModal
          onConfirm={() => {
            removeModal();
            toggleJobVisibility();
          }}
          onCancel={() => {
            removeModal();
          }}
          plan={currentOrganization?.plan}
        />
      );

      openModal(modal);
    } else {
      toggleJobVisibility();
    }
  };

  const toggleJobVisibility = () => {
    updateJob({ id: job.id, visible: !job.visible });
  };

  const handleClickOnPublishJob = () => {
    if (currentOrganization.stripeSubscriptionInGoodStanding) {
      handlePublishJob();
    } else if (!planBasedBillingEnabled && currentOrganization.stripeDefaultPaymentMethodOnFile) {
      createSubscription(
        {},
        {
          onSuccess: (data) => {
            window.logger("%c[JobSetupContainer] createSubscription SUCCESS", "color: #19d219", {
              data,
            });
            handlePublishJob();
          },
          onError: (response) => {
            window.logger("%c[JobSetupContainer] createSubscription FAILURE", "color: #990f6b", {
              response,
            });
            addToast({
              title: "Unable to create subscription",
              kind: "warning",
            });
          },
        },
      );
    } else {
      const modal = planBasedBillingEnabled ? (
        <SubscriptionRequiredModalNew
          onCancel={() => removeModal()}
          isAdmin={currentOrganizationUser.isAdmin}
          jobId={job.id}
          location={location}
        />
      ) : (
        <SubscriptionRequiredModal
          onCancel={() => removeModal()}
          isAdmin={currentOrganizationUser.isAdmin}
          jobId={job.id}
          location={location}
        />
      );

      openModal(modal);
    }
  };

  const handlePublishJob = () => {
    publishJob(job, {
      onSuccess: (data) => {
        const title =
          data.status && data.status === "in_review"
            ? "This job post will be reviewed by the Polymer team!"
            : `Published ${props.job.title}`;

        addToast({
          title,
          kind: "success",
        });
      },
      onError: (data) => {
        addToast({
          title: "Only admins are allowed to publish",
          kind: "warning",
        });
      },
    });
  };

  const onCloseModal = () => {
    removeModal();
  };

  const handleClickOnShareJob = (event) => {
    event.preventDefault();
    const modal = (
      <ShareJobModal
        job={job}
        jobUrl={jobUrl}
        onCancel={() => {
          removeModal();
        }}
      />
    );
    openModal(modal);
  };

  const handleClickOnCloneJob = (e) => {
    e.preventDefault();
    openModal(() => <CloningJobModal onCancel={onCloseModal} />);

    cloneJob(job, {
      onSuccess: (data) => {
        removeModal();
        props.history.push(`/jobs/${data.id}/setup`);
      },
      onError: (data) => {
        removeModal();

        addToast({
          title: `Could not clone ${job.title}`,
          kind: "warning",
        });
      },
    });
  };

  const handleClickOnArchive = (event) => {
    event.preventDefault();

    const modal = (
      <ArchiveJobModal
        jobStatus={job.status}
        onConfirm={() => {
          removeModal();
          archiveJob();
        }}
        onCancel={() => {
          removeModal();
        }}
      />
    );

    openModal(modal);
  };

  const archiveJob = () => {
    updateJob(
      { id: job.id, status: "status_archived" },
      {
        onSuccess: (data) => {
          window.logger("%c[JobSetupContainer] archiveJob onSuccess", "color: #1976D2");
          addToast({
            title: `Archived ${job.title}`,
            kind: "success",
          });
        },
      },
    );
  };

  const handleClickOnUpdateOnWebflow = (event) => {
    event.preventDefault();
    // This is effectively a fake update that should trigger the AR callbacks to update on Webflow
    forceWebflowSyncForJob(
      {
        id: job.id,
      },
      {
        onSuccess: () => {
          addToast({
            title: "Starting sync with Webflow",
            kind: "success",
          });
        },
      },
    );
  };

  const jobControlOverflow = () => {
    let archiveJobButtonNode = null;
    let syncWithWebflowButtonNode = null;
    let hideJobButtonNode = null;
    let cloneJobButtonNode = (
      <button onClick={handleClickOnCloneJob}>
        <span>Clone job</span>
      </button>
    );

    if (job.status === "published") {
      archiveJobButtonNode = <button onClick={handleClickOnArchive}>Archive job</button>;
      if (job.visible) {
        hideJobButtonNode = <button onClick={handleToggleJobVisibility}>Hide job post</button>;
      }
      if (currentOrganization.hasWebflowMapping) {
        syncWithWebflowButtonNode = (
          <button onClick={handleClickOnUpdateOnWebflow} aria-label="Update job on Webflow">
            Force sync
          </button>
        );
      }
    }

    return (
      (currentOrganizationUser.isAdmin || hideJobButtonNode || syncWithWebflowButtonNode) && (
        <DropdownMenu button hpos="right" vpos="top">
          {currentOrganizationUser.isAdmin && cloneJobButtonNode}
          {hideJobButtonNode}
          {currentOrganizationUser.isAdmin && archiveJobButtonNode}
          {syncWithWebflowButtonNode}
        </DropdownMenu>
      )
    );
  };

  const jobControls = () => {
    if (job.status === "published" && job.visible) {
      return (
        <Styled.Actions>
          <Button styleType="secondary" onClick={handleClickOnShareJob}>
            <span>Share job post</span>
            {/* <Icon name="share" /> */}
          </Button>
          {jobControlOverflow()}
        </Styled.Actions>
      );
    } else if (job.status === "published" && !job.visible) {
      return (
        <Styled.Actions>
          <Button styleType="secondary" onClick={handleToggleJobVisibility}>
            {/* {job.visible ? "Hide job post" : "Make post visible"} */}
            Make post visible
          </Button>
          {jobControlOverflow()}
        </Styled.Actions>
      );
    } else if (job.status === "status_archived") {
      return (
        <Styled.Actions>
          {/* <Button styleType="secondary" onClick={unArchiveJob}>
            Un-Archive Job
          </Button> */}
          {jobControlOverflow()}
        </Styled.Actions>
      );
    } else {
      return (
        <Styled.Actions>
          <Button
            onClick={handleClickOnPublishJob}
            loading={isLoadingCreateSubscription || isLoadingPublishJob}
            aria-label="Publish job"
          >
            Publish job post
          </Button>
          {jobControlOverflow()}
        </Styled.Actions>
      );
    }
  };

  return (
    <>
      <Helmet title="Job setup" />
      <Styled.Container>
        <Styled.Sidebar>
          <NavItem to={`${match.url}/details`} label="Job details" chevron />
          <NavItem to={`${match.url}/description`} label="Job description" chevron />
          <NavItem to={`${match.url}/application`} label="Application form" chevron />
          <NavItem to={`${match.url}/document`} label="Document template" chevron />
          <NavItem to={`${match.url}/stages`} label="Hiring stages" chevron />
          <NavItem to={`${match.url}/team`} label="Hiring team" chevron />
          <NavItem to={`${match.url}/automations`} label="Automations" chevron />

          {/* <Styled.NavLink to={`${match.url}/process`}>
                        <Styled.Name>
              <Icon name="circle" />
              <div>Hiring Process</div>
            </Styled.Name>
            <Icon name="chevron-right" />
          </Styled.NavLink> */}
          {currentOrganizationUser.role === "god_admin" && (
            <NavItem to={`${match.url}/polymerAdmin`} label="Polymer admin" chevron />
          )}

          <Styled.Divider />
          <Styled.Preview>
            <Styled.PreviewTitle>Job post</Styled.PreviewTitle>
            {!currentOrganization.stripeSubscriptionInGoodStanding ||
            job.status !== "published" ||
            (job.status === "published" && !job.visible) ? (
              <Styled.PreviewLink>
                <Icon name="external-link" />
                <button onClick={handleClickOnViewPreview} data-testid="preview-job-link">
                  Preview job post{" "}
                </button>
              </Styled.PreviewLink>
            ) : (
              <Styled.PreviewLink>
                <Icon name="link" />
                <Link
                  to={jobUrl}
                  target="_blank"
                  rel="noopener"
                  onClick={(e) => e.stopPropagation()}
                  data-testid="job-post-link"
                >
                  {removeHttpPrefix(jobUrl)}
                </Link>
              </Styled.PreviewLink>
            )}
          </Styled.Preview>

          <Styled.Divider />
          <Styled.Controls>{jobControls()}</Styled.Controls>
        </Styled.Sidebar>

        <UnsavedChangesGuard
          hasUnsavedChanges={isDirty}
          navigate={(pathname) => {
            window.logger(
              "%c[JobSetupContainer] UnsavedChangesGuard navigate",
              "color: #1976D2",
              pathname,
            );
            setIsDirty(false);
            props.history.push(pathname);
          }}
        />

        <Styled.Content>
          <Switch>
            <Route
              path={`${match.path}/details`}
              render={(renderProps) => (
                <JobSetupDetails {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/description`}
              render={(renderProps) => (
                <JobSetupDescription {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/application`}
              render={(renderProps) => (
                <JobSetupApplicationForm {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/document`}
              render={(renderProps) => (
                <JobSetupDocument {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/stages`}
              render={(renderProps) => (
                <JobSetupHiringStages {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/team`}
              render={(renderProps) => (
                <JobSetupTeam {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            <Route
              path={`${match.path}/automations`}
              render={(renderProps) => (
                <JobSetupAutomations {...props} {...renderProps} setIsDirty={setIsDirty} />
              )}
            />
            {currentOrganizationUser.role === "god_admin" && (
              <Route
                path={`${match.path}/polymerAdmin`}
                render={(renderProps) => <div>ADMIN</div>}
              />
            )}
            <Redirect to={`${match.url}/details`} />
          </Switch>
        </Styled.Content>
      </Styled.Container>
    </>
  );
}

JobSetupContainer.propTypes = {};

JobSetupContainer.defaultProps = {};

export default JobSetupContainer;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div`
  label: JobSetupContainer;
  display: flex;
  height: 100%;
  overflow-x: auto;
`;

Styled.Sidebar = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupContainer_Sidebar;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    border-right: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    flex-shrink: 0;
    width: 50vw;
    padding-top: 0.375rem;

    ${t.mq["lg"]} {
      width: 21.5909091%;
      flex-shrink: 1;
    }
  `;
});

Styled.Content = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupContainer_Content;
    height: 100%;
    overflow-y: auto;
    position: relative;

    ${t.mq["lg"]} {
      width: 78.4090909%;
    }
  `;
});

Styled.Divider = styled.hr((props) => {
  const t: any = props.theme;
  return css`
    label: JobStagesContainer_Divider;
    ${[t.mb("px")]}
    margin-top: 2.25rem;
    width: 100%;
    border: 0;
  `;
});

Styled.Controls = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupContainer_Controls;
    ${[t.px(4), t.pb(4)]}
  `;
});

Styled.Actions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupContainer_Actions;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    > button:first-of-type {
      ${[t.mr(3), t.mb(3)]};
    }
  `;
});

Styled.Preview = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupContainer_Preview;
    ${[t.px(4)]}
  `;
});

Styled.PreviewTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupContainer_PreviewTitle;
    ${[t.text.xs, t.text.bold, t.mb(2)]}
    color: ${t.dark ? t.color.gray[300] : t.color.black};
  `;
});

Styled.PreviewLink = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobSetupContainer_PreviewLink;
    ${[t.text.xs, t.h(6), t.mb("px")]}
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
    display: flex;
    align-items: center;
    overflow: hidden;
    svg {
      ${t.mr(2)}
      flex-shrink: 0;
    }
    div,
    a,
    button {
      color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
      text-align: start;
      cursor: pointer;
      padding: 0;
      appearance: none;
      outline: none;
      background: transparent;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    button:hover,
    button:focus,
    a:hover {
      text-decoration: underline;
    }
  `;
});
