export interface Organization {
  id: number;
  name: string;
  description: string | null;
  publishedJobsCount: number;
  logoMedium: string | null;
  hasLogo: boolean;
  usersCount: number;
}

export interface CurrentOrganization extends Organization {
  websiteUrl: string;
  remoteness: string;
  stripeSubscriptionInGoodStanding: boolean;
  stripeDefaultPaymentMethodOnFile: boolean;
  stripePromoCode: string | null;
  stripeSubscriptionStatus: string | null;
  stripeCurrentPeriodEndAt?: string;
  stripeSubscription?: any;
  hasWebflowMapping: boolean;
  wwrCompanyStatement: string;
  wwrCompanyBio: string;
  plan: string;
  careersPages: CareersPage[];
  flipperId: string;
  flipperGroup: string;
  careersPageUrl: string;
  careersPageSlug: string;
  settings: any;
  users: any;
  organizationUsers: any[];
  ownerId: number;
  hasWebflowAuthentication: boolean;
  webflowIntegration: any;
  hasSlackAuthentication: boolean;
  slackChannelIntegration: any;
  hasDiscordAuthentication: boolean;
  discordChannelIntegration: any;
  zapierApiKey: string;
  linkedinCompanyId: number;
  enabledLinkedinBasicJobs: boolean;
  enableXHiring: boolean;
  webflowAuthenticatedViaApiV2: boolean;
}

interface CareersPage {
  id: number;
  slug: string;
  additionalContent: string;
  hasCustomNavigation: boolean;
  customNavigationUrl: string;
  logoVisibility: string;
  showPublishedAtTimes: boolean;
  socialShareImageType: string;
  hasLogo: boolean;
  logoFilename: string;
  logoMedium: string;
  socialShareImageUrl: string;
  socialShareImageFilename: string;
  hasSocialShareImage: boolean;
  billboardTitle: string;
  billboardIntro: string;
  billboardVideoUrl: string;
  billboardImageUrl: string;
  previewBillboardImageUrl: string;
  billboardImageFilename: string;
  hasBillboardImage: boolean;
  billboardMediaType: string;
  customDomain: string;
  hasFavicon: boolean;
  faviconFilename: string;
  faviconUrl: string;
}

export enum Plans {
  NO_PLAN = "plan_no_plan",
  SIMPLE_ATS_FREE = "plan_simple_ats_free",
  SIMPLE_ATS_PAID = "plan_simple_ats_paid",
  SIMPLE_ATS_PER_JOB = "plan_simple_ats_per_job",
  ATS_TIER_GEMINI = "plan_ats_tier_gemini",
  ATS_TIER_APOLLO = "plan_ats_tier_apollo",
  ATS_TIER_ARTEMIS = "plan_ats_tier_artemis",
}
