import React, { useState } from "react";
import { Link } from "react-router-dom";

import Icon from "@ats/src/components/shared/Icon";
import DropdownMenu from "@ats/src/components/shared/DropdownMenu";
import ArchiveJobModal from "@ats/src/components/modals/ArchiveJobModal";
import CloningJobModal from "@ats/src/components/modals/CloningJobModal";
import HideJobModal from "@ats/src/components/modals/HideJobModal";

import { useCloneJob, useUpdateJob } from "@shared/queryHooks/useJob";
import { useToastContext } from "@shared/context/ToastContext";
import { useModalContext } from "@shared/context/ModalContext";
import { getTimestamp } from "@shared/queryHooks/useOrganization";

function JobListItemMenu(props) {
  const { job, currentOrganization, currentUser } = props;
  const addToast = useToastContext();
  const { openModal, removeModal } = useModalContext();
  const { mutate: cloneJob, isLoading: isLoadingCloneJob } = useCloneJob();
  const { mutate: updateJob, isLoading: isLoadingUpdateJob } = useUpdateJob();

  // const slug = currentOrganization.careersPages[0].slug;
  const jobUrl = `${currentOrganization?.careersPageUrl}/${job.id}`;

  const handleClickOnViewPreview = () => {
    getTimestamp().then((data) => {
      window.open(`${jobUrl}/preview/${data.timestamp}`, "_blank");
    });
  };

  const handleToggleJobVisibility = (event) => {
    event.preventDefault();
    if (job.visible === true) {
      const modal = (
        <HideJobModal
          onConfirm={() => {
            removeModal();
            toggleJobVisibility();
          }}
          onCancel={() => {
            removeModal();
          }}
          plan={currentOrganization?.plan}
        />
      );

      openModal(modal);
    } else {
      toggleJobVisibility();
    }
  };

  const toggleJobVisibility = () => {
    updateJob({ id: job.id, visible: !job.visible });
  };

  const archiveJob = () => {
    updateJob(
      { id: job.id, status: "status_archived" },
      {
        onSuccess: (data) => {
          window.logger("%c[JobSetupContainer] archiveJob onSuccess", "color: #1976D2");
          addToast({
            title: `Archived ${job.title}`,
            kind: "success",
          });
        },
      },
    );
  };

  const handleClickOnArchive = (event) => {
    event.preventDefault();

    const modal = (
      <ArchiveJobModal
        jobStatus={job.status}
        onConfirm={() => {
          removeModal();
          archiveJob();
        }}
        onCancel={() => {
          removeModal();
        }}
      />
    );

    openModal(modal);
  };

  const handleCloneJob = (e) => {
    e.preventDefault();

    openModal(<CloningJobModal onCancel={() => removeModal()} />);

    cloneJob(job, {
      onSuccess: (data) => {
        removeModal();
        props.history.push(`/jobs/${data.id}/setup`);
      },
      onError: (data) => {
        removeModal();

        addToast({
          title: `Could not clone ${job.title}`,
          kind: "warning",
        });
      },
    });
  };

  let viewJobPostingButtonNode = null;
  let visibilityToggleSwitchNode = null;
  let publishJobButtonNode = null;
  let archiveJobButtonNode = null;

  const cloneJobButtonNode = (
    <button onClick={handleCloneJob}>
      <span>Clone job</span>
    </button>
  );

  switch (job.status) {
    case "published":
      visibilityToggleSwitchNode = (
        <button onClick={handleToggleJobVisibility}>
          <span>{job.visible ? "Hide job post" : "Make post visible"}</span>
        </button>
      );

      if (currentOrganization.stripeSubscriptionInGoodStanding && job.visible) {
        viewJobPostingButtonNode = (
          <Link
            to={`${currentOrganization?.careersPageUrl}/${job.id}`}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            View job post
            <Icon name="external-link" />
          </Link>
        );
      } else if (!currentOrganization.stripeSubscriptionInGoodStanding || !job.visible) {
        viewJobPostingButtonNode = (
          <button onClick={handleClickOnViewPreview}>
            Preview job post
            <Icon name="external-link" />
          </button>
        );
      }

      archiveJobButtonNode = <button onClick={handleClickOnArchive}>Archive job</button>;
      break;

    case "draft":
      viewJobPostingButtonNode = (
        <button onClick={handleClickOnViewPreview}>
          Preview job post
          <Icon name="external-link" />
        </button>
      );

      publishJobButtonNode = (
        <button onClick={props.publishJob} aria-label="Publish job">
          Publish job post
        </button>
      );

      archiveJobButtonNode = <button onClick={handleClickOnArchive}>Archive job</button>;
      break;
  }

  return (
    <DropdownMenu testid="joblist-item-dropdown-menu">
      {publishJobButtonNode}
      {viewJobPostingButtonNode}
      {visibilityToggleSwitchNode}
      {currentUser.currentOrganizationUser.isAdmin && cloneJobButtonNode}
      {currentUser.currentOrganizationUser.isAdmin && archiveJobButtonNode}
    </DropdownMenu>
  );
}

JobListItemMenu.propTypes = {};

JobListItemMenu.defaultProps = {};

export default JobListItemMenu;
